* {
  padding: 0;
  box-sizing: border-box;
  font-weight: 600;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App{
  max-width: 500px;
  width: 100%;
  min-height: 400px;
  box-shadow: 0 2px 6px #555;
  margin: 50px auto;
  padding: 50px;

}
h1{
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 900;
  color: #666;

}
form{
  width: 100%;
  height: 40px;
  margin: 20px 0;
  display: flex;

}
form input,
form button {
  flex: 3;
  border: none;
  outline: none;
  border-bottom: 1px solid #555;
  margin: 0 5px;

}
form button {
  flex: 1;
  background: #555;
  color: white;
  letter-spacing: 2px;
  cursor: pointer;

}

ul li {
  list-style: none;
  border: 1px solid #ccc;
  margin: 10px 0;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;

}
ul li .active{
  opacity: 0.5;
  text-decoration: line-through;
}
ul li input,
.row input {
  margin-right: 7px;
  transform: translateY(1px);

}
ul li button ,
.row button {
  border: none;
  outline: none;
  color: white;
  background: orange;
  padding: 5px 15px;
  cursor: pointer;

}
ul li button:disabled{
  opacity: 0.7;
  cursor: not-allowed;

}
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 22px;

}

.row #delete{
  background: crimson;
  
}

label {
  cursor: pointer;
}
@media (max-width:400px){
  .App{

  }
}